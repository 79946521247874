import { useEffect, useRef } from 'react';

import { usePathname } from 'next/navigation';

export const useHeaderBgListener = () => {
  const fixed = useRef(false);
  const pathname = usePathname();
  useEffect(() => {
    const handleScroll = () => {
      const upTo = pathname == '/' ? (window.innerWidth > 768 ? 240 : 100) : 100;
      if (fixed.current !== window.scrollY > upTo) {
        if (document.body.getAttribute('data-fixed') != 'true') {
          document.body.setAttribute('data-fixed', 'true');
        }
      } else {
        if (document.body.getAttribute('data-fixed') != 'false') {
          document.body.setAttribute('data-fixed', 'false');
        }
      }
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    handleScroll();

    const config = { attributes: true, childList: false, subtree: false };
    const callback: MutationCallback = (mutationList) => {
      const mutation = mutationList[0];

      if (mutation.attributeName == 'data-fixed') {
        setTimeout(() => {
          const upTo = pathname == '/' ? (window.innerWidth > 768 ? 240 : 100) : 100;
          if (window.scrollY == upTo) {
            window.scrollBy(0, document.body.getAttribute('data-fixed') == 'true' ? 20 : -10);
          }
        });
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe(document.body, config);

    // Later, you can stop observing

    return () => {
      window.removeEventListener('scroll', handleScroll);
      observer.disconnect();
    };
  }, [pathname]);
};

export default useHeaderBgListener;
