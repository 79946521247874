'use client';

import { ReactNode, Suspense, lazy, useState } from 'react';

import clx from '../../utils/clx';
import Icon from '../Icon';

import SubmitButton from './ButtonSubmit';

export const isEmpty = (value: string) => ['+', '+1', ''].includes(value);

const InputPhone = lazy(() => import('../Input/InputPhone'));

type Props = React.HTMLProps<HTMLInputElement> & {
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  buttonClassName?: string;
  buttonLabel?: string;
  terms?: ReactNode;
  pending?: boolean;
};

const InputPhoneNumber = ({
  placeholder,
  buttonLabel,
  className,
  onFocus,
  onBlur,
  inputClassName,
  buttonClassName,
  terms,
  pending,
}: Props) => {
  const [showPlaceholder, setShowPlaceholder] = useState(Boolean(placeholder));
  const handleFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    event.persist();
    onFocus?.(event);
    setShowPlaceholder(false);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    event.persist();
    onBlur?.(event);

    if (isEmpty(event.target.value)) {
      setShowPlaceholder(Boolean(placeholder));
    }
  };
  return (
    <div
      className={clx(
        'relative h-10 w-[min(380px,90vw)] opacity-100 transition-[opacity,width,height] [&_.special-label]:!hidden',
        className
      )}
    >
      <Suspense fallback={null}>
        <InputPhone
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputClass={inputClassName}
          showCountryFlag={!showPlaceholder}
        />
      </Suspense>
      {showPlaceholder && (
        <div className='pointer-events-none absolute inset-0 top-0 flex h-full w-full items-center gap-2 pl-5 peer-valid:hidden peer-invalid:hidden peer-focus-within:hidden'>
          <Icon name='mobile' className='top-0 h-6 w-6 text-4-light-grey' />
          <span>{placeholder}</span>
        </div>
      )}
      {terms}
      <SubmitButton
        label={buttonLabel}
        className={clx(
          'btn-sm absolute right-0.5 top-1/2 z-10 w-36 -translate-y-1/2 transition-[right]',
          buttonClassName
        )}
        pending={pending}
      />
    </div>
  );
};

export default InputPhoneNumber;
