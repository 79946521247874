'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

import Logo from '@wander/ui/src/components/Logo';
import clx from '@wander/ui/src/utils/clx';

import FormGetApp from '@/features/downloadApp/components/FormGetApp';
import { HeaderSlotWrapper } from './HeaderSlot';
import './style.css';
import ButtonBurger from '@wander/ui/src/components/Button/ButtonBurger';
import { Suspense, lazy } from 'react';

const Nav = lazy(() => import('./Nav'));

type Props = {
  id?: string;
  className?: string;
  invert?: boolean;
};

const Desktop = ({ className, invert = false, ...props }: React.HTMLProps<HTMLDivElement> & Props) => {
  const pathname = usePathname();
  const showDownloadAppButton = pathname !== '/operated';

  return (
    <div
      {...props}
      data-invert={invert ? 'true' : undefined}
      className='header pointer-events-none fixed left-0 top-0 z-50 w-full'
    >
      <header
        className={clx(
          'container relative mx-auto flex h-fit min-h-[4rem] w-full max-w-screen-xl flex-wrap items-start justify-between  text-white transition-[height]',
          showDownloadAppButton ? 'px-2' : 'px-5 md:px-16 xl:px-20',
          className
        )}
      >
        {pathname === '/' ? (
          <a
            href='/'
            aria-label='Wander logo'
            className='pointer-events-auto flex h-16 w-40 flex-none items-center  pl-2'
          >
            <Logo invert={invert} />
          </a>
        ) : (
          <Link
            href='/'
            aria-label='Wander logo'
            className='pointer-events-auto flex h-16 w-40 flex-none items-center  pl-2'
            scroll={true}
          >
            <Logo invert={invert} />
          </Link>
        )}
        <HeaderSlotWrapper className='order-1 w-full flex-none sm:order-none sm:w-auto sm:flex-1 sm:justify-center' />

        <div className='pointer-events-auto flex h-16 w-28 flex-none items-center justify-end max-lg:hidden sm:w-fit'>
          {showDownloadAppButton && <FormGetApp invert={invert} className='mr-4' buttonLabel='✓' terms={false} />}
          <Suspense fallback={<ButtonBurger disabled />}>
            <Nav invert={invert} />
          </Suspense>
        </div>
      </header>
    </div>
  );
};

export default Desktop;
