'use client';

import { Action, Description, Root, Title } from '@radix-ui/react-toast';
import Icon from '../Icon';

export type NotificationInfo = {
  type: 'SUCCESS' | 'ERROR' | 'LOADING';
  title: string;
  description?: string;
  duration?: number;
};

type Props = {
  notification?: NotificationInfo;
  closeNotification: () => void;
};

const Notification = ({ notification, closeNotification }: Props) => {
  return (
    <Root
      open={Boolean(notification)}
      onOpenChange={closeNotification}
      className="bg-w-90015 text-white data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=end]:animate-swipeOut grid grid-cols-[auto_max-content] items-center gap-x-[15px] rounded-md p-[15px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] [grid-template-areas:_'title_action'_'description_action'] data-[swipe=cancel]:translate-x-0 data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:transition-[transform_200ms_ease-out]"
    >
      <Title className=' mb-[5px] text-[15px] font-medium [grid-area:_title]'>{notification?.title}</Title>
      {notification?.description ? (
        <Description className='m-0 text-[13px] leading-[1.3] text-opacity-40 [grid-area:_description]'>
          {notification.description}
        </Description>
      ) : null}
      <Action className='[grid-area:_action]' asChild altText='close'>
        <button>
          <Icon name='close' />
        </button>
      </Action>
    </Root>
  );
};

export default Notification;
