'use client';

import { ReactNode, useEffect, useState, useTransition } from 'react';

import clx from '../../utils/clx';
import Icon from '../Icon';

import { IconProps } from '../Icon/Icon';
import InputPhoneNumber, { isEmpty } from './InputPhoneNumber';

const DisplayLabel = ({ label, className }: { label: string; className?: string }) => (
  <span
    className={clx(
      'absolute top-1/2 block w-full -translate-y-1/2 opacity-0 transition-all duration-700 ease-in-out',
      className
    )}
  >
    {label}
  </span>
);

const AnimatedLabels = () => {
  const list = [
    { id: 1, text: 'Get the Wander app' },
    { id: 2, text: 'to access Concierge' },
    { id: 3, text: 'Get the Wander app' },
    { id: 4, text: 'to manage your trip' },
    { id: 5, text: 'Get the Wander app' },
    { id: 6, text: 'to give friends $250' },
  ];

  const [index, setIndex] = useState(0);
  const [_, startTransition] = useTransition();
  const loopIndex = (list.length + index) % list.length;

  const lens = [
    list.at((list.length + loopIndex - 1) % list.length),
    list.at(loopIndex),
    list.at((list.length + loopIndex + 1) % list.length),
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      startTransition(() => {
        setIndex((index) => index + 1);
      });
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='relative whitespace-nowrap font-medium'>
      {lens[0] ? <DisplayLabel key={lens[0].id} label={lens[0].text} className='-translate-y-full' /> : null}
      {lens[1] ? <DisplayLabel key={lens[1].id} label={lens[1].text} className='z-50 opacity-100' /> : null}
      {lens[2] ? <DisplayLabel key={lens[2].id} label={lens[2].text} className='translate-y-0' /> : null}
    </div>
  );
};

type State = 'NotAsked' | 'Sent' | 'Error';

type Props = {
  btnClassName?: string;
  label?: string;
  placeholder?: string;
  buttonLabel?: string;
  forceOpen?: boolean;
  terms?: ReactNode;
  invert?: boolean;
  state: State;
  className?: string;
  pending?: boolean;
  IconComponent?: React.ComponentType<IconProps>;
};

// million-ignore
const GetAppButton = ({
  btnClassName,
  label,
  placeholder,
  buttonLabel,
  forceOpen = false,
  terms,
  invert = false,
  className,
  state,
  pending,
  IconComponent = Icon,
}: Props) => {
  const [_isFilled, setIsFilled] = useState(forceOpen);
  const [_phoneInput, setupPhoneInput] = useState(forceOpen);

  const isFilled = forceOpen || _isFilled;
  const phoneInput = forceOpen || _phoneInput;

  return (
    <div className={clx('overflow-hidden', isFilled ? 'w-[220px]' : 'w-[183px]', className)}>
      <div className='flex flex-col items-center justify-center' onMouseOver={() => setupPhoneInput(true)}>
        <div
          className={clx(
            'btn btn-invert btn-sm relative w-full !scale-100 !px-0 text-sm',
            '!border-white/20 !bg-1-black/50 backdrop-blur-md',
            'from-[#141414] to-[#1A1A1A] group-[&[data-fixed="true"]]/body:!border-w-8002 group-[&[data-fixed="true"]]/body:bg-gradient-to-b group-[&[data-fixed="true"]]/body:backdrop-blur-[0]',
            invert && '!border-w-8002 bg-gradient-to-b backdrop-blur-[0]',
            isFilled ? 'w-full focus-within:border-white/60' : 'flex w-[183px] min-w-[auto]'
          )}
        >
          {state === 'Sent' ? (
            <div className='flex h-8 items-center px-4'>
              <IconComponent name='checkmark' className='top-0 mr-2 font-base text-white' />
              <span>We&apos;ve texted you a link to download our app!</span>
            </div>
          ) : (
            <>
              {phoneInput ? (
                <InputPhoneNumber
                  className={clx('!w-full', !isFilled && 'opacity-0')}
                  pending={pending}
                  onFocus={() => setIsFilled(true)}
                  onBlur={(event) => {
                    if (isEmpty(event.target.value)) {
                      setIsFilled(forceOpen);
                    }
                  }}
                  inputClassName={clx(
                    'opacity-0 focus:opacity-100',
                    isFilled && !placeholder?.length && 'opacity-100',
                    !isFilled && 'cursor-pointer'
                  )}
                  buttonClassName={!isFilled ? 'pointer-events-none -right-full' : '!h-6 right-[3px] !rounded w-8'}
                  buttonLabel={buttonLabel}
                  placeholder={forceOpen ? '' : placeholder}
                  terms={terms}
                />
              ) : undefined}
              <div
                className={clx(
                  'btn btn-md pointer-events-none absolute z-50 !w-full justify-start !bg-transparent text-inherit opacity-100',
                  btnClassName,
                  isFilled && 'opacity-0'
                )}
              >
                <IconComponent name='mobile' className='top-0 h-6 w-6 text-w-4004' />
                {label || <AnimatedLabels />}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GetAppButton;
