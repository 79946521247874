import { useLayoutEffect, useMemo, useState } from 'react';
import yieldToMainThread from '../utils/yieldToMainThread';

export const sizeMin = {
  xxl: (width: number) => width >= 1536,
  xl: (width: number) => width >= 1440,
  lg: (width: number) => width >= 1024,
  md: (width: number) => width >= 768,
  sm: (width: number) => width >= 640,
};

type Sizes = {
  width: number;
  height: number;
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Sizes | undefined>(undefined);

  useLayoutEffect(() => {
    const handleSize = () => {
      yieldToMainThread().then(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      });
    };

    handleSize();

    window.addEventListener('resize', handleSize, { passive: true });

    return () => window.removeEventListener('resize', handleSize);
  }, []);

  return windowSize;
};

export default useWindowSize;

export const useMinSize = () => {
  const size = useWindowSize();

  return useMemo(
    () =>
      size
        ? {
            xxl: () => sizeMin.xxl(size.width),
            xl: () => sizeMin.xl(size.width),
            lg: () => sizeMin.lg(size.width),
            md: () => sizeMin.md(size.width),
            sm: () => sizeMin.sm(size.width),
          }
        : undefined,
    [size]
  );
};
