'use client';

import { createRef, useEffect, useSyncExternalStore } from 'react';
import { createPortal } from 'react-dom';

import clx from '@wander/ui/src/utils/clx';

const SLOT_ID = 'header-slot';

const slotRef = createRef<HTMLDivElement>();
const slotListenerRef = {
  current: null as (() => void) | null,
};
const slotReady = () => {
  slotListenerRef.current?.();
};

const slotSubscriber = (listener: () => void) => {
  slotListenerRef.current = listener;
  return () => {
    slotListenerRef.current = null;
  };
};

type Props = {
  className?: string;
};

export const HeaderSlotWrapper = ({ className }: Props) => {
  useEffect(() => {
    slotReady();
  }, []);

  return <div ref={slotRef} id={SLOT_ID} className={clx('relative flex flex-1 items-center', className)} />;
};

const useHeaderSlot = () => {
  const slot = useSyncExternalStore<HTMLElement | null>(
    slotSubscriber,
    () => slotRef.current,
    () => null
  );

  return slot;
};

const HeaderSlot = ({ children }: { children: React.ReactNode }) => {
  const slot = useHeaderSlot();
  try {
    if (slot) {
      return <>{createPortal(<>{children}</>, slot)}</>;
    }
    return undefined;
  } catch (e) {
    console.error(`No matching element with id ${SLOT_ID} found`);
    return children as JSX.Element;
  }
};

export default HeaderSlot;
