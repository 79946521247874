'use client';

import Button from '../Button/Button';
import LoaderDots from '../Loader/LoaderDots';

type Props = {
  label?: string;
  className?: string;
  pending?: boolean;
};
const ButtonSubmit = ({ label = 'Get the app', className, pending = false }: Props) => {
  return (
    <Button disabled={pending} className={className}>
      {pending ? <LoaderDots className='w-6' /> : label}
    </Button>
  );
};

export default ButtonSubmit;
