'use client';

import { lazy } from 'react';

import { usePathname } from 'next/navigation';

import Responsive from '@wander/ui/src/components/Responsive';

import Desktop from './Desktop';
import useHeaderBgListener from './useHeaderBgListener';

const Mobile = lazy(() => import('./Mobile'));

const showHeaderBar = (pathName: string) => pathName != '/' && !pathName.startsWith('/property/');

type Props = {
  id?: string;
  className?: string;
  invert?: boolean;
};

const WanderHeader = (props: Props) => {
  const pathName = usePathname();
  useHeaderBgListener();

  if (pathName?.startsWith('/reit/')) {
    return null;
  }

  return (
    <Responsive
      lg={() => <Desktop key='desktop' {...props} />}
      base={() => (
        <>
          {pathName && showHeaderBar(pathName) ? <Desktop key='desktop' {...props} /> : null}
          <Mobile {...props} />
        </>
      )}
      fallback={() => <Desktop key='desktop' {...props} className='max-lg:hidden' />}
    />
  );
};

export default WanderHeader;
