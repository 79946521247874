'use client';

import { ComponentProps, useCallback, useState } from 'react';

import GetAppButton from '@wander/ui/src/components/GetAppButton/GetAppButton';
import { isEmpty } from '@wander/ui/src/components/GetAppButton/InputPhoneNumber';
import useNotify from '@wander/ui/src/hooks/useNotify';

import Link from 'next/link';
import { useFormStatus } from 'react-dom';
import getMobileApp from '../actions/getMobileApp';

type Props = {
  terms?: boolean;
} & Omit<ComponentProps<typeof GetAppButton>, 'state' | 'terms'>;

const FormGetApp = ({ terms = true, ...props }: Props) => {
  const [state, setState] = useState<ComponentProps<typeof GetAppButton>['state']>('NotAsked');
  const notify = useNotify();
  const { pending } = useFormStatus();

  const handleSubmit = useCallback(
    async (formData: FormData) => {
      const phoneNumber = formData.get('phoneNumber');

      if (phoneNumber && !isEmpty(String(phoneNumber))) {
        try {
          await getMobileApp(formData);
          setState('Sent');
        } catch (_e) {
          notify.error({
            title: 'Something went wrong.',
            description: 'Ensure phone number is valid',
          });
        }
      }
    },
    [notify]
  );

  return (
    <form action={handleSubmit}>
      <GetAppButton
        state={state}
        pending={pending}
        {...props}
        terms={
          terms && (
            <p className='absolute top-10 py-2 text-sm text-white/80 peer-focus-within:block'>
              I authorize SMS and agree to the{' '}
              <Link href='/terms' className='text-white'>
                terms and conditions
              </Link>{' '}
              and{' '}
              <Link href='/privacy' className='text-white'>
                privacy policy
              </Link>
              .
              <br />
              Data and messaging rates may apply.
            </p>
          )
        }
      />
    </form>
  );
};

export default FormGetApp;
