'use client';

import { createContext, useContext, useState } from 'react';

import * as Toast from '@radix-ui/react-toast';
import Notification, { type NotificationInfo } from '../components/Notifications/Notification';

const NotificationContext = createContext<((notification: NotificationInfo) => void) | undefined>(undefined);

const NotificationsProvider = ({ children }: { children: React.ReactNode }) => {
  const [info, setInfo] = useState<NotificationInfo | undefined>(undefined);

  return (
    <NotificationContext.Provider value={setInfo}>
      <Toast.Provider swipeDirection='right' duration={info?.duration || 5000}>
        <>{children}</>
        <Notification notification={info} closeNotification={() => setInfo(undefined)} />
        <Toast.Viewport className='fixed max-lg:top-0 max-lg:w-full bottom-0 right-0 z-[2147483647] m-0 flex w-[390px] max-w-[100vw] list-none flex-col gap-[10px] p-[var(--viewport-padding)] outline-none [--viewport-padding:_25px]' />
      </Toast.Provider>
    </NotificationContext.Provider>
  );
};

export default NotificationsProvider;

const useNotification = () => {
  const notification = useContext(NotificationContext);
  if (!notification) {
    throw new Error('useNotification must be used within a NotificationsProvider');
  }
  return notification;
};

type Notify = Omit<NotificationInfo, 'type'>;
export const useNotify = () => {
  const notifier = useNotification();

  return {
    success: ({ title, description }: Notify) => notifier({ type: 'SUCCESS', title, description }),
    error: ({ title, description }: Notify) => notifier({ type: 'ERROR', title, description }),
  };
};
