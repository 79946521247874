import { forwardRef } from 'react';

import clx from '../../utils/clx';

const Button = forwardRef(
  (
    {
      className,
      ...props
    }: React.HTMLProps<HTMLButtonElement> & {
      type?: 'button' | 'submit' | 'reset';
    },
    ref: React.Ref<HTMLButtonElement>
  ) => <button className={clx('btn', className)} {...props} ref={ref} />
);

Button.displayName = 'Button';

export default Button;
