import clx from '../../utils/clx';

type LineProps = {
  className?: string;
};

const Line = ({ className }: LineProps) => (
  <div
    className={clx('h-px w-[1.6rem] rounded-full border-t-[1.5px] border-white transition duration-300', className)}
  />
);

type Props = {
  opened?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  innerRef?: React.Ref<HTMLButtonElement>;
};

const ButtonBurger = ({
  innerRef,
  opened = false,
  className,
  ...props
}: Omit<React.HTMLProps<HTMLButtonElement>, 'onClick'> & Props) => (
  <button
    className={clx('group flex h-8 w-8 flex-col items-end justify-evenly pr-[3px]', className)}
    {...props}
    type='button'
    ref={innerRef}
  >
    <Line className={opened ? 'translate-y-[8.5px] rotate-45' : undefined} />
    <Line className={opened ? 'opacity-0' : 'w-[21px]'} />
    <Line className={opened ? '-translate-y-[8.5px] -rotate-45' : 'w-[15px]'} />
  </button>
);

export default ButtonBurger;
