import clx from '../../utils/clx';

import type { AllIcons, IconsByNs } from './types';

export type IconName = AllIcons;

export type IconProps = React.SVGProps<SVGSVGElement> & {
  className?: string;
  fill?: string;
  stroke?: string;
} & IconsByNs;

// million-ignore
const Icon = ({ name, className, fill = 'currentColor', stroke = 'currentColor', namespace = 'icon' }: IconProps) => (
  <svg
    className={clx('relative top-[.125em] h-[1.2em] w-[1.2em] shrink-0', className)}
    fill={fill}
    stroke={stroke}
    viewBox='0 0 100 100'
  >
    <use href={`/${namespace}.svg#${namespace}-${name}`}></use>
  </svg>
);

export default Icon;
